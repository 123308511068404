import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutMe.css";

export default function AboutMe(props) {

    let fadeInScreenHandler = (screen) => {
        if (screen.fadeInScreen !== props.id) return;
        Animations.animations.fadeInScreen(props.id);
    };
    const fadeInSubscription =
        ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

    const SCREEN_CONSTSANTS = {
        description:
            "Software developer with five years of experience in developing web applications in a variety of domains and tech stacks. My strongest skills are Angular, React, and Node.js. Experienced in designing web application architectures and solutions for rapid scaling.",
        highlights: {
            bullets: [
                "Building web Solutions",
                "SQL & No-SQL Database Development",
                "Building hybrid applications using Ionic",
                "Building REST-API with Node.js and Express.js",
            ],
            heading: "Here are a Few Highlights:",
        },
    };
    const renderHighlight = () => {
        return (
            SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
                <div className="highlight " key={i}>
                    <div className="highlight-blob "></div>
                    <span>{value}</span>
                </div>
            ))
        )
    };

    return (
        <div
            className="about-me-container screen-container fade-in "
            id={props.id || ""}
        >
            <div className="about-me-parent ">
                <ScreenHeading title={"About Me"} subHeading={"My Portfolio Overview"} />
                <div className="about-me-card ">
                    <div className="about-me-profile "></div>
                    <div className="about-me-details ">
                        <span className="about-me-description ">
                            <br />
                            {SCREEN_CONSTSANTS.description}
                        </span>
                        <div className="about-me-highlights ">
                            <div className="highlight-heading ">
                                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
                            </div>
                            {renderHighlight()}
                        </div>
                        <div className="about-me-options ">
                            <button className="btn primary-btn"
                                onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
                            > Let's Discuss!
                            </button>
                            <a href="Peter_Munyus_Resume.pdf" download="Peter Munyua Resume.pdf">
                                <button className="btn highlighted-btn ">Get My Resume</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
