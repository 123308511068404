import './FooterBg.css'

const FooterBg = () => {
    return (
        <div className='footer-container'>
            <div className='footer-parent'>
                <img src={require('../../../assets/Home/shape-bg.png').default} alt='no internet connection'/>
            </div>
        </div>
    );
};

export default FooterBg
