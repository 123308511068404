import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Projects.css";
import FooterBg from "../Home/FooterBg/FooterBg";
import FastlaneImage from "../../images/fastlane.png"
import ArdhisasaImage from "../../images/ardhisas.png"
import FreeAdvertImage from "../../images/freeadvert.png"
import SkillcatImage from "../../images/skillcat.png"
import ZuruImage from "../../images/zuru.png"
import BootcampImage from "../../images/bootcamp.png"
import FastlaneAdminImage from "../../images/fastlane-admin.png"

export default function Projects(props) {
    let fadeInScreenHandler = (screen) => {
        if (screen.fadeInScreen !== props.id) return;
        Animations.animations.fadeInScreen(props.id);
    };

    const fadeInSubscription =
        ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

    const options = {
        loop: true,
        margin: 0,
        nav: true,
        animateIn: "bounceInRight",
        animateOut: "bounceOutRight",
        dots: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1000: {
                items: 2,
            },
        },
    };

    return (
        <div>
            <ScreenHeading
                title={"My Projects"}
                subHeading={"Below is some of the project I have been involved in."}
            />
            <section className="project-section fade-in" id={props.id || ""}>
                <div className="container">
                    <div className="row">
                        <OwlCarousel
                            className="owl-carousel"
                            id="project-carousel"
                            {...options}
                        >
                            <div className="col-lg-12">
                                <div className="project-item">
                                    <div className="project-desc">
                                        <img src={SkillcatImage} alt='' />
                                    </div>
                                    <div className="project-info">
                                        <h5>Skillcat Application</h5>
                                        <p>- Stack: Angular, Ionic and Cordova</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="project-item">
                                    <div className="project-desc">
                                        <img src={FastlaneImage} alt='' />
                                    </div>
                                    <div className="project-info">
                                        <h5>Fastlane Spirits</h5>
                                        <p>- Stack: Angular, SCSS, Bootstrap, and pdfMake</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="project-item">
                                    <div className="project-desc">
                                        <img src={FastlaneAdminImage} alt='' />
                                    </div>
                                    <div className="project-info">
                                        <h5>Fastlane Spirits Admin</h5>
                                        <p>- Stack: Angular, SCSS, Bootstrap, and pdfMake</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="project-item">
                                    <div className="project-desc">
                                        <img src={ArdhisasaImage} alt='' />
                                    </div>
                                    <div className="project-info">
                                        <h5>Ardhisasa</h5>
                                        <p>- Stack: Angular, SCSS, Angular Material, Django REST, LeafLet and pdfMake</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="project-item">
                                    <div className="project-desc">
                                        <img src={BootcampImage} alt='' />
                                    </div>
                                    <div className="project-info">
                                        <h5>Bootcamp Blog</h5>
                                        <p>- Stack: Angular, SCSS, Bootstrap, Node and Express</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="project-item">
                                    <div className="project-desc">
                                        <img src={ZuruImage} alt='' />
                                    </div>
                                    <div className="project-info">
                                        <h5>Zuru Traveling</h5>
                                        <p>- Stack: Pug, CSS, Bootstrap, Node and Express</p>
                                    </div>
                                </div>
                            </div>

                        </OwlCarousel>
                    </div>
                </div>
                <FooterBg />
            </section>

        </div>
    );
}
