import Header from './Header/Header';
import Profile from './Profile/Profile';
import './Home.css';
import FooterBg from "./FooterBg/FooterBg";

export default function Home(props) {
    return (
        <div className='home-container' id={props.id || ""}>
            <Header/>
            <Profile/>
            <FooterBg/>
        </div>
    );
}

