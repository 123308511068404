import React, {useState} from 'react';
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";

const Resume = (props) => {
    const [selectedBulletIndex, setSelectedBulletIndex] = useState(0)
    const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({})

    let fadeInScreenHandler = (screen) => {
        if (screen.fadeInScreen !== props.id) return;

        Animations.animations.fadeInScreen(props.id);
    };
    const fadeInSubscription =
        ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

    const ResumeHeading = (props) => {
        return (
            <div className="resume-heading">
                <div className="resume-main-heading">
                    <div className="heading-bullet"></div>
                    <span>{props.heading ? props.heading : ""}</span>
                    {props.fromDate && props.toDate ? (
                        <div className="heading-date">
                            {props.fromDate + "-" + props.toDate}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className="resume-sub-heading">
                    <span>{props.subHeading ? props.subHeading : ""}</span>
                </div>
                <div className="resume-heading-description">
                    <span>{props.description ? props.description : ""}</span>
                </div>
            </div>
        );
    };

    const resumeBullets = [
        {label: "Education", logoSrc: "education.svg"},
        {label: "Work History", logoSrc: "work-history.svg"},
        {label: "Programming Skills", logoSrc: "programming-skills.svg"},
        {label: "Projects", logoSrc: "projects.svg"},
        {label: "Interests", logoSrc: "interests.svg"},
    ];

    const programmingSkillsDetails = [
        {skill: "Angular", ratingPercentage: 85},
        {skill: "React", ratingPercentage: 75},
        {skill: "Ionic", ratingPercentage: 75},
        {skill: "Node JS", ratingPercentage: 75},
        {skill: "Express", ratingPercentage: 75},
        {skill: "HTML", ratingPercentage: 80},
        {skill: "CSS", ratingPercentage: 80},
        {skill: "JavaScript", ratingPercentage: 80}
    ];


    const projectsDetails = [
        {
            title: "Ardhisasa Web Portal",
            duration: {fromDate: "June 2019", toDate: "July 2021"},
            description:
                "A land management system web portal. Live application: https://ardhisasa.co.ke",
            subHeading: "Technologies Used: Node JS, Angular, Angular Material, SCSS, JavaScript, pdfMake.",
        },
        {
            title: "FreeAdvert Web Application",
            duration: {fromDate: "2021", toDate: "2022"},
            description:
                "An ecommerce web application that offers free advertising for small businesses.",
            subHeading:
                "Technologies Used:  Angular, CSS, Bootstrap, pdfMake, JavaScript,  Django REST, AWS",
        },
        {
            title: "Fastlane Spirits Web Application",
            duration: {fromDate: "2022", toDate: "2022"},
            description:
                "An online liquor store.",
            subHeading:
                "Technologies Used:  Angular, CSS, Bootstrap, pdfMake, JavaScript,  Django REST, AWS",
        },
    ];

    const resumeDetails = [
        <div className="resume-screen-container" key="education">
            <ResumeHeading
                heading={"Taita Taveta University(TTU)"}
                subHeading={"Bachelor of Science in Mathematics and Computer Science"}
                fromDate={"2015"}
                toDate={"2019"}
            />

            <ResumeHeading
                heading={"Endarasha Boys High School"}
                subHeading={"KCPE Certificate"}
                fromDate={"2010"}
                toDate={"2014"}
            />
        </div>,

        /* WORK EXPERIENCE */
        <div className="resume-screen-container" key="work-experience" style={{overFlow: 'auto'}}>
            <div className="experience-container">
                <ResumeHeading
                    heading={"SkillCat - Hanover Hermann Park, United States"}
                    subHeading={"Software Developer"}
                    fromDate={"2021"}
                    toDate={"Present"}
                />

                <div className="experience-description">
            <span className="resume-description-text">
              - Review project specifications and design technology solutions that met.
            </span>
                </div>
                <div className="experience-description">
            <span className="resume-description-text">
              - Collaborate with business unit team members to design new applications system to enhance client requirements for mobile application.
            </span>
                    <br/>
                    Developed a hybrid mobile application using Ionic, AngularJS, and Cordova.
                    <span className="resume-description-text">
              - Coordinate with other engineers to evaluate and improve software interfaces.
            </span>
                    <br/>

                    <br/>
                    <span className="resume-description-text">
              - Developed a hybrid mobile application using Ionic, AngularJS, and Cordova.
            </span>
                    <br/>
                </div>
            </div>
        </div>,

        /* PROGRAMMING SKILLS */
        <div className="resume-screen-container programming-skills-container" key="programming-skills">
            {programmingSkillsDetails.map((skill, index) => (
                <div className="skill-parent" key={index}>
                    <div className="heading-bullet"/>
                    <span>{skill.skill}</span>
                    <div className="skill-percentage">
                        <div
                            style={{width: skill.ratingPercentage + "%"}}
                            className="active-percentage-bar"
                        />
                    </div>
                </div>
            ))}
        </div>,

        /* PROJECTS */
        <div className="resume-screen-container" key="projects">
            {projectsDetails.map((projectsDetails, index) => (
                <ResumeHeading
                    key={index}
                    heading={projectsDetails.title}
                    subHeading={projectsDetails.subHeading}
                    description={projectsDetails.description}
                    fromDate={projectsDetails.duration.fromDate}
                    toDate={projectsDetails.duration.toDate}
                />
            ))}
        </div>,

        /* Interests */
        <div className="resume-screen-container" key="interests">
            <ResumeHeading
                heading="Sports"
                description="Like to cycling and swimming."
            />
            <ResumeHeading
                heading="Traveling"
                description="I love traveling and exploring nature."
            />
            <ResumeHeading
                heading="Gaming"
                description="Playing Chess."
            />
        </div>,
    ];

    const handleCarousal = (index) => {
        let offsetHeight = 360;

        let newCarousalOffset = {
            style: {transform: "translateY(" + index * offsetHeight * -1 + "px)"},
        };

        setCarousalOffsetStyle(newCarousalOffset);
        setSelectedBulletIndex(index);
    };

    const getBullets = () => {
        return resumeBullets.map((bullet, index) => (
            <div
                onClick={() => handleCarousal(index)}
                className={
                    index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
                }
                key={index}
            >
                <img
                    className="bullet-logo"
                    src={require(`../../assets/Resume/${bullet.logoSrc}`).default}
                    alt="B"
                />
                <span className="bullet-label">{bullet.label}</span>
            </div>
        ));
    };

    const getResumeScreens = () => {
        return (
            <div
                style={carousalOffsetStyle.style}
                className="resume-details-carousal"
            >
                {resumeDetails.map((ResumeDetail) => ResumeDetail)}
            </div>
        );
    };

    return (
        <div className="resume-container screen-container fade-in" id={props.id || ""}>
            <div className="resume-content">
                <ScreenHeading title={"Resume"} subHeading={"My formal Bio Details"}/>
                <div className="resume-card">
                    <div className="resume-bullets">
                        <div className="bullet-container">
                            <div className="bullet-icons"/>
                            <div className="bullets">{getBullets()}</div>
                        </div>
                    </div>
                    <div className="resume-bullet-details">{getResumeScreens()}</div>
                </div>
            </div>
        </div>
    );
};

export default Resume;

